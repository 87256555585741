/**
 * @description 全局调用工具组件，同一组件支持多子应用调用
 * @module types
 */
import type SlotGlobalToolkit from 'slot-global-toolkit';
import type { EventCenterObserverCallback, EventCenterObserverCallbackParams } from '@/services/global-api/index.types';
import { App, createApp } from 'vue';
import { removeDomScope } from '@micro-zoe/micro-app';
import { BaseMessage } from '@suite/shared';
import { handleError, handleGlobalComponents } from '@/entry';
import eventCenter from '@/services/global-api/event/eventCenter';
import GlobalPopupTpl from './main.vue';

/**
 * 创建插槽元素
 * @param id
 * @returns
 */
function createSlotElement(id: string): HTMLDivElement {
  const div = document.createElement('div');
  div.id = `slot-wrap-${id}`;
  div.style.width = '100%';  // 设置宽度为100%
  div.style.height = '100%'; // 设置高度为100%
  return div;
}

/**
 * 获取事件名
 * @param pluginCode
 * @param eventName
 * @returns
 */
function getEventName(pluginCode: string, eventName: string): string {
  return `${pluginCode}:${eventName}`;
}

export class GlobalToolkit implements SlotGlobalToolkit.IGlobalToolkit {
  /**
   * 全局弹窗插槽实例
   */
  private appInst: App | undefined;

  /**
   * 全局弹窗插槽导出方法
   */
  private get appExposed(): Record<string, any> | null {
    return this.appInst?._instance?.exposed || null;
  }

  constructor(
    /**
     * 插件 Code
     */
    public readonly pluginCode: string,
    /**
     * 打开全局工具包传参
     */
    public readonly pluginProps: SlotGlobalToolkit.IOpenProps,
    /**
     * 全局工具包 宿主元素
     */
    public readonly container: HTMLElement,
  ) {

  }

  /**
   * 打开全局工具盒
   */
  open(): void {
    this.initPlugin();
    if (!this.appExposed) return;
    if (this.appExposed.visible?.value) return;
    this.appExposed?.open();
  }
  /**
   * 更新全局工具盒参数
   */
  update(pluginCode: string, payload: SlotGlobalToolkit.IOpenProps): void {
    if (pluginCode !== this.pluginCode) return;
    Object.assign(this.pluginProps, payload);
    console.log('更新全局工具盒参数', this.pluginProps, payload);
    if (!this.appInst?._instance?.props) return;
    this.appExposed?.update(payload);
  }

  /**
   * 关闭全局工具盒
   */
  close(): void {
    // 清除监听事件
    eventCenter.clearMatch(new RegExp(`^${this.pluginCode}`));
    if (!this.appInst) return;
    // 销毁 dom 节点
    if (this.appInst._container) {
      this.container.removeChild(this.appInst._container);
    }
    // 卸载 Vue 实例
    this.appInst.unmount();
    this.appInst = undefined;
  }

  /**
   * 监听插件事件
   * @param eventName
   * @param callback
   */
  $on(eventName: string, callback: EventCenterObserverCallback): void {
    const event = getEventName(this.pluginCode, eventName);
    eventCenter.on(event, callback);
  }

  /**
   * 初始化 Plugin
   */
  private initPlugin() {
    console.log('打开全局工具组件', this.pluginCode, this.pluginProps, this.container);
    try {
      // 创建注入元素时，需要解除子应用下的元素绑定，否则会把注入资源加载到子应用内，导致位置显示异常
      removeDomScope();
      this.appInst = createApp(GlobalPopupTpl, {
        pluginCode: this.pluginCode,
        pluginProps: this.pluginProps,
        pluginConfigs: {},
        emits: this.emits.bind(this),
        destroySlot: this.close.bind(this),
      });
      handleGlobalComponents(this.appInst);
      handleError(this.appInst);
      this.appInst.mount(this.container.appendChild(createSlotElement(this.pluginCode)));
    } catch (e) {
      console.warn('打开工具组件失败', e);
      BaseMessage({
        type: 'error',
        message: '工具组件加载失败，请稍后重试',
      });
    }
  }

  /**
   * 发出事件
   * @param eventName
   * @param args
   */
  private emits(eventName: string, ...args: EventCenterObserverCallbackParams[]): void {
    const event = getEventName(this.pluginCode, eventName);
    eventCenter.dispatch(event, ...args);
  }
}
