/*
 * @Author: cocogong
 * @Date: 2024-05-28 17:19:09
 * @LastEditTime: 2024-10-09 11:03:28
 * @LastEditors: cocogong
 * @Description: util
 */
export function getRightCopilotWidth(): number {
  const windowWidth = window.innerWidth;
  if (windowWidth >= 1920) {
    return Math.min(windowWidth * 0.25, 480);
  }
  return Math.max(windowWidth * 0.25, 380);
}

export const DEFAULT_RIGHT_COPILOT_WIDTH = getRightCopilotWidth();

export function adjustPos(curX: number, curY: number, width: number, height: number) {
  return {
    left: (curX + width) > window.innerWidth - 10 ? (window.innerWidth - width - 10) : curX,
    isBottom: (curY + height) > window.innerHeight - 10,
    top: (curY + height) > window.innerHeight - 10 ? (window.innerHeight - height - 10) : curY,
  };
}
// 问答场景
export const OLA_CHAT_ANSWER_PRODUCT_TYPE = {
  TOOL: 2,
  SMALL: 3,
};

// olaChat提交问题的意图识别类型
export const OLA_CHAT_INTENTION_TYPE_MAP = {
  TABLE: 8,  // 找表
  METRICS: 3, // 指标计算,指标意图
  OPT_SQL: 11, // SQL优化,智能纠错
  EXPLAIN_SQL: 12, // SQL解读
  DATA_INTERPRETER: 13, // 结果解读
  SQL_QA: 15, // SQL问答, sql知识问答
  SQL: 5,   // 生成SQL
  // 文档问答
  USER_GUID: 4,
  DSL_ANALYSIS: 19, // DSL分析
};

export const ACTION_TYPE = {
  CODE: 'CODE', // 指标插入光标
  DATA_EXPLORE_RESULT: 'DATA_EXPLORE_RESULT', // 回填
  USER_INPUT: 'UserInput',
  CHAT_INTTELLIGENT_DRAW: 'Chat_Intelligent_Draw',
  CHAT_METRIC_DIM_INFO: 'Chat-Metric-Dim-Info', // text2dsl,dsl回填
  PARSE_AUTH_APPLY_URL: 'PARSE_AUTH_APPLY_URL', // 解析 authApplyUrl
  DATA_ANALYSIS: 'DATA_ANALYSIS', // 数据表用于分析
};

export const ANALYSIS_SCENE_TYPE = {
  INTEGRATED_DRAG: 1,
  DASHBOARD: 2,
  SQL: 3,
};

