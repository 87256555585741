/**
 * @description 权限系统跳转地址封装
 * @module types
 */
import {
  replace,
  forEach,
  get,
} from 'lodash-es';
import { useMicroAppStore } from '@/stores/micro-app';
import type { BEAppKeyType } from '@/constants/micro';
import type { LocationRes } from '@/services/global-api/modules/authorize';

/**
 * 此接口用来约束path里的替换变量
 */
interface RouterPathData {
  bizId: string,
  appKey: string,
}

/**
 * 构建完整 URL
 * @param path {string}
 * @returns
 */
export const buildFullPath = function buildFullPath(path: string): LocationRes {
  const microStore = useMicroAppStore();
  const dataauthAppConfig = microStore.getAppConfig('dataauth');
  if (!dataauthAppConfig?.baseroute) return {
    fullPath: '',
    href: '',
  };
  const fullPath = `${dataauthAppConfig.baseroute}${path}`;
  return {
    fullPath,
    href: `${location.origin}${fullPath}`,
  };
};

// 替换字符串中的{}字符
function replaceString(path = '', parmas: RouterPathData): string {
  let tempPath = path;
  forEach(parmas, (value, key) => {
    tempPath = replace(tempPath, `{${key}}`, value);
  });

  return tempPath;
}

// dataauth 企业设置 路径
export function getCorpSettingsPath(corpId: string): string {
  return `/company/${corpId}/basic`;
}

// 内外网 dataauth 权限设置 路径
export function getPermissionPath(bizId: string, appKey: BEAppKeyType): string {
  return replaceString(window.AppConfig.dataauthPaths.permissionPath, {
    bizId,
    appKey,
  });
}

// 内外网 dataauth 个人设置 路径
export function getPersonalPath(): string {
  return get(window.AppConfig, 'dataauthPaths.personalPath');
}

// dataauth 空间权限管理 路径
export function getSpaceManagementPath(corpId: string, bizId: string, appKey: string = ''): string {
  return appKey ? `/spaceManage/${corpId}/${bizId}/basic/${appKey}` : `/spaceManage/${corpId}/${bizId}/basic`;
}

// dataauth 新增业务 路径
export function getAddBusinessPath(corpId: string): string {
  return `/company/${corpId}/business`;
}

/**
 * 内外网 dataauth 企业列表 路径
 * @param ifAutoSkip {boolean} 租户列表页是否自动进入租户（默认用户只有一个租户的情况下会自动进入）
 * @returns
 */
export function getCorpListPath(ifAutoSkip = true): string {
  const enterpriseListPath = get(window.AppConfig, 'dataauthPaths.enterpriseListPath');
  if (ifAutoSkip) {
    return enterpriseListPath;
  }
  return `${enterpriseListPath}?not_skip=1`;
}

// dataauth 登陆页面 路径
export function getLoginPath(): string {
  return '/login';
}
