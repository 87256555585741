/**
 * @description 子应用相关枚举
 * @module types
 */

// 后端 AppId
export type BEAppKeyType = 'beacon_olap'
|'data_talk'
|'data_link'
|'data_py'
|'datahub3_team'
|'tab'
|'mix_query'
|'console'
|'data_kit'
|'cdp'
|'data_manager'
|'data_auth'
|'metacat'
|'monitor'
|'push'
|'resource_management'
|'venus'
|'wuji'
|'template'
|'data_reporting'
|'xingpan'
|'tx'
|'fabric'
|'wetable'
|'excelin'
|'ola-lab'
|'ola-govern'
|'ola-discover'
|'ola-metric'
|'datong'
|'oval'
|'tdebug'
|'tab-ab-experiment'
|'tab-causal-inference'
|'tab-config-and-switch';


export const AppKeyMapping: Record<MicroApp.AppKeyType, BEAppKeyType> = {
  datainsight: 'beacon_olap',
  datatalk: 'data_talk',
  'datatalk-card': 'data_talk',
  datalink: 'data_link',
  datahub: 'datahub3_team',
  datapy: 'data_py',
  tab: 'tab',
  venus: 'venus',
  wuji: 'wuji',
  'wuji-source': 'wuji',
  mixquery: 'mix_query',
  console: 'data_kit',
  dataaudience: 'cdp',
  datamanager: 'data_manager',
  dataauth: 'data_auth',
  dataassets: 'metacat',
  monitor: 'monitor',
  push: 'push',
  resourcemanagement: 'resource_management',
  template: 'template',
  datareporting: 'data_reporting',
  'mobile-control-app': 'console',
  xingpan: 'xingpan',
  tx: 'tx',
  fabric: 'fabric',
  wetable: 'excelin',
  'ola-lab': 'ola-lab',
  'ola-lab-quality': 'ola-lab',
  'ola-govern': 'ola-govern',
  'ola-discover': 'ola-discover',
  'ola-metric': 'ola-metric',
  datong: 'datong',
  oval: 'oval',
  tdebug: 'tdebug',
  'tab-ab-experiment': 'tab-ab-experiment',
  'tab-causal-inference': 'tab-causal-inference',
  'tab-config-and-switch': 'tab-config-and-switch',
};

export enum AppState {
  // 未开通
  NoOpen = 0,
  // 已经开通
  Open,
  // 即将过期
  BeforeExpired,
  // 已过期
  Expired
}

// 是否开启业务管理，1是，0否
export enum EnabledBusi {
  // 否
  Disabled = 0,
  // 是
  Enabled
}

// Session storage 内存储 ’最近访问组织架构信息' key
export const BEACON_RECENT_VISITED_ORG_INFO = '__BEACON_RECENT_VISITED_ORG_INFO__';

export const APP_TYPE_TEXT: {[k in MicroApp.AppType]?: string} = {
  dataAnalyze: '数据分析',
  devGovernance: '数据治理',
  dataApplication: '数据应用',
  dataScience: '数据科学',
  otherApp: '其他应用',
};
