import { computed } from 'vue';
import { useNavigationStore } from '@/stores/navigation';
import globalAPI from '@/services/global-api';

const bizId = computed(() => useNavigationStore()?.getCurrentBizId);

// DSL 字段回填处理逻辑
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fieldBackfillHandler(data: any): void {
  const SUPPORT_AUTO_BACKFILL_PAGE_REG = new RegExp(`^/datatalk/${bizId.value}/card`);
  const { pathname } = window.location;
  const isSupportAutoBackFill = SUPPORT_AUTO_BACKFILL_PAGE_REG.test(pathname);

  if (isSupportAutoBackFill) {
    globalAPI.olaChat?.olaChatBackfilllQueryParams(data);
  } else {
    const analysisDSL = data?.data?.analysisDSL;
    if (analysisDSL) {
      analysisDSL.isAnalysisDSL = true;
      analysisDSL.metaInfo = data?.data?.metaInfos?.[0];
    }
    const backfillData = data?.data?.targetItem || data?.data?.allData || analysisDSL;
    if (backfillData) {
      localStorage.setItem('BEACON_OLA_COPILOT_BACKFILL_DATA', JSON.stringify(backfillData));
      window.open(`${window.location.origin}/datatalk/${bizId.value}/card?autoBackfill=true`, '_blank');
    }
  }
}

